.edge-stack-status {
  padding: 2px 10px;
  border-radius: 10px;
}

.edge-stack-status.status-acknowledged {
  color: #337ab7;
  background-color: rgba(51, 122, 183, 0.1);
}

.edge-stack-status.status-images-pulled {
  color: #e1a800;
  background-color: rgba(238, 192, 32, 0.1);
}

.edge-stack-status.status-ok {
  color: #23ae89;
  background-color: rgba(35, 174, 137, 0.1);
}

.edge-stack-status.status-error {
  color: #ae2323;
  background-color: rgba(174, 35, 35, 0.1);
}

.edge-stack-status.status-total {
  background-color: rgba(168, 167, 167, 0.1);
}
