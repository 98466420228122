.btn-none {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
}

.btn-none:focus {
  outline: none;
}

.btn-warninglight {
  @apply border-warning-5 bg-warning-2 text-black;
  @apply th-dark:bg-warning-5 th-dark:bg-opacity-10 th-dark:text-white;
  @apply th-highcontrast:bg-warning-5 th-highcontrast:bg-opacity-10 th-highcontrast:text-white;
}
